<template lang="pug">
td.whitespace-nowrap.p-2(:class="isFirst ? '' : ''", :colspan="colspan"): slot
</template>

<script>
export default {
  props: {
    isFirst: [Boolean, String],
    colspan: { type: [Number, String], default: 1 }
  }
};
</script>
