<template lang="pug">
.relative.flex.flex-col.mt-10
  .flex.overflow-x-auto(v-if="ok")
    router-link.font-medium.h-10.mr-8.text-base.text-gray-400(
      v-for="cl in classes",
      :key="cl.id",
      :to="{name: 'timesheet', params: {classId: cl.id}}",
      class="hover:text-gray-700 whitespace-nowrap"
      active-class="border-b-2 border-gray-900 text-gray-700"
    ) {{ getClassName(cl.id) }}

  CoreInfo(v-if="!ok", type="danger", icon="mdi-alert-circle-outline")
    p Ошибка загрузки данных. Попробуйте позднее или перезагрузить страницу.

  CoreLoading(v-if="loading")
</template>

<script>
import { classes, loadClasses, getClassName } from "@/app/store/classes.store";

export default {
  data() {
    return {
      ok: true,
      loading: false,
      error: null,

      classes: classes
    };
  },

  async mounted() {
    await this.loadClasses();
  },

  methods: {
    getClassName,
    async loadClasses() {
      try {
        this.loading = true;
        const { data } = loadClasses();
        this.classes = data;
        this.loading = false;
        this.ok = true;
      } catch (error) {
        this.ok = false;
        this.loading = false;
        this.error = error;
      }
    }
  }
};
</script>
