<template lang="pug">
tr.border-t(
  :class="isLast ? 'bg-gray-100 text-xs' : 'text-sm'"
): slot
</template>

<script>
export default {
  props: {
    isLast: [Boolean, String]
  }
};
</script>
