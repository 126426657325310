<template>
  <div class="app-header" ref="header" :class="[{ scrolled }, variant]">
    <div class="left"><slot name="left"></slot></div>
    <div class="page-title"><slot></slot></div>
    <div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    variant: { type: String },
    scrolled: { type: Boolean, default: false }
  },

  mounted() {
    this.handleScroll();

    if (this.scrolled) {
      addEventListener("scroll", this.handleScroll);
    }
  },

  beforeUnmount() {
    if (this.scrolled) {
      removeEventListener("scroll", this.handleScroll);
    }
  },

  methods: {
    handleScroll() {
      if (window.scrollY > 32) {
        this.$refs.header.classList.add("is-active");
      } else {
        this.$refs.header.classList.remove("is-active");
      }
    }
  }
};
</script>

<style scoped>
.app-header {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background: #fff;
  color: #141515;
  /*box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);*/
  @apply shadow;
}

.app-header.scrolled {
  background: transparent;
  box-shadow: none;
  border: none;
}

.app-header.scrolled.is-active {
  background: #1e74fd;
  color: #ffffff;
  @apply shadow;
}

.app-header.scrolled.is-active .page-title {
  opacity: 1;
}

.app-header.scrolled .page-title {
  opacity: 0;
}

.left,
.right {
  height: 56px;
  display: flex;
  align-items: center;
  position: absolute;
}

.left {
  left: 8px;
  top: 0;
  font-size: 24px;
}

.right {
  right: 8px;
  top: 0;
  font-size: 24px;
}

.page-title {
  font-size: 16px;
  font-weight: 500;
  padding: 0 16px;
}

.transparent {
  background: transparent;
  box-shadow: none;
}

.no-border {
  border: none;
  box-shadow: none;
}

.app-header.colored {
  color: #ffffff;
  background: #1e74fd;
}
</style>
