<template lang="pug">
header.header.bg-pink-200.py-4.pb-16(class="sm:py-6 sm:pb-24")
  CoreContainer
    .flex.items-center.justify-between
      CoreLogo
      .flex
        Profile
        ReloadButton.mr-4
        LogoutButton
    CoreTitle(v-if="title") {{ title }}
</template>

<script>
export default {
  props: {
    title: String
  }
};
</script>

<style scoped>
.header {
  background: url("../assets/images/bg.jpg") #fbcfe8;
}
</style>
