<template>
  <div class="mb-4 relative z-0 md:mb-8">
    <header class="header">
      <div class="border-b py-4 mb-4 md:mb-8">
        <core-container>
          <div class="flex justify-between">
            <core-logo></core-logo>

            <div class="flex items-center">
              <profile />
              <reload-button class="mr-4" />
              <logout-button />
            </div>
          </div>
        </core-container>
      </div>

      <core-title>{{ title }}</core-title>

      <!--      <div class="header__actions">-->
      <!--        <p class="text-gray-500 mb-2 text-xs font-medium">-->
      <!--          Выберите действие-->
      <!--        </p>-->

      <!--        <div class="flex">-->
      <!--          <div class="flex mr-4 flex-col items-center justify-start">-->
      <!--            <div-->
      <!--              class="max-h-10 h-10 rounded-md px-2 pr-4 items-center justify-center flex bg-white shadow-md text-xl"-->
      <!--            >-->
      <!--              <icon icon="mdi-archive-outline"></icon>-->
      <!--              <p-->
      <!--                class="ml-2 text-sm  whitespace-nowrap leading-none font-medium text-gray-700"-->
      <!--              >-->
      <!--                Архив заявок-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div class="flex mr-4 flex-col items-center justify-start">-->
      <!--            <div-->
      <!--              class="max-h-10 h-10 rounded-md px-2 pr-4 items-center justify-center flex bg-white shadow-md text-xl"-->
      <!--            >-->
      <!--              <icon icon="mdi-autorenew"></icon>-->

      <!--              <p-->
      <!--                class="ml-2 text-sm whitespace-nowrap leading-none font-medium text-gray-700"-->
      <!--              >-->
      <!--                Восстановление-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </header>
  </div>
</template>

<script>
import CoreTitle from "@/app/shared/core/CoreTitle";
import CoreContainer from "@/app/shared/core/CoreContainer";
import Profile from "@/app/shared/components/Profile";
import ReloadButton from "@/app/shared/components/Buttons/ReloadButton";
export default {
  components: { ReloadButton, Profile, CoreContainer, CoreTitle },
  props: {
    title: String
  }
};
</script>

<style scoped>
/*.header {*/
/*  background: url("../assets/images/bg.jpg") #fbcfe8;*/
/*  background-size: cover;*/
/*}*/

.header {
  /*@apply fixed top-0 w-full py-4 bg-pink-200 pb-14;*/
  /*@apply md:static;*/
}

.header__actions {
  @apply flex flex-col mt-2 w-full;
  @apply md:hidden;
}

.header__title {
  @apply text-2xl font-medium text-gray-700;
  @apply md:text-3xl;
}
</style>
