import { ref } from "vue";
import { cloneDeep, find, isUndefined } from "lodash";

const customSet = ref([]);

export function useCustomSet() {
  const addInSet = (food, type) => {
    let cs = find(customSet.value, ({ name, price }) => {
      return name === food.name && price === food.price;
    });

    if (isUndefined(cs)) {
      cs = cloneDeep(food);
      cs.count = 0;
      cs.type = type;

      customSet.value.push(cs);
    }

    cs.count += 1;
  };

  const removeFromSet = index => {
    customSet.value.splice(index, 1);
  };

  const clearSet = () => {
    customSet.value = [];
  };

  return {
    customSet,
    addInSet,
    removeFromSet,
    clearSet
  };
}
