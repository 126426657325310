<template>
  <core-card title="Заявка">
    <template #actions>
      <core-button
        @click="closeRequest"
        class="h-8 relative leading-none pl-4 pr-10"
        type="success"
      >
        <icon class="absolute right-4" icon="mdi-arrow-right" />
        Отправить заявку
      </core-button>
    </template>

    <div class="overflow-x-auto">
      <core-table class="w-full">
        <core-table-body>
          <template v-for="(learner, index) in learners" :key="index">
            <core-table-row v-if="index % 10 === 0">
              <core-table-cell class="text-xs font-medium" is-first>
                #
              </core-table-cell>
              <core-table-cell class="text-xs font-medium">
                ФИО, баланс
              </core-table-cell>
              <core-table-cell class="text-xs font-medium">
                Отсутствует
              </core-table-cell>
              <core-table-cell class="text-xs font-medium">
                Свой заказ
              </core-table-cell>
              <core-table-cell
                class="text-xs font-medium"
                v-for="fs in notArchivalFs"
                :key="fs.id"
              >
                {{ fs.name }}
              </core-table-cell>
            </core-table-row>

            <core-table-row :class="isPositionInvalid(learner) && 'bg-red-100'">
              <core-table-cell>{{ index + 1 }}</core-table-cell>
              <core-table-cell>
                <div>
                  <p>{{ learner.surname }} {{ learner.name }}</p>
                  <p
                    class="text-xs font-medium text-pink-600"
                    v-if="learner.socialGroups.length"
                  >
                    ✓ Соц.: {{ createSocialNames(learner.socialGroups) }}
                  </p>
                  <p class="text-xs font-medium text-gray-500">
                    Доступно:
                    <span class="text-pink-800">
                      {{ formatCurrency(learner.available) }}
                    </span>
                  </p>
                </div>
              </core-table-cell>

              <core-table-cell>
                <core-toggle
                  :value="getEmptyModel(learner.id)"
                  @change="setEmptyModel(learner.id)"
                />
              </core-table-cell>

              <core-table-cell>
                <core-button
                  v-if="!learner.socialGroups.length"
                  @click="openCustomSet(learner.id)"
                  :type="isPositionCustom(learner.id) ? 'info' : 'default'"
                >
                  {{ isPositionCustom(learner.id) ? "Изменить" : "Создать" }}
                </core-button>
              </core-table-cell>

              <core-table-cell v-for="fs in notArchivalFs" :key="fs.id">
                <core-toggle
                  v-if="isFsAllow(learner, fs)"
                  :disabled="isLocked(learner.id, fs.id)"
                  @change="setFsModel(learner.id, fs.id)"
                  :value="getFsModel(learner.id, fs.id)"
                />
              </core-table-cell>
            </core-table-row>
          </template>
        </core-table-body>
      </core-table>
    </div>

    <Menu
      v-if="showModal"
      :options="modalOptions"
      @close="closeOpenCustom"
      @result="addCustomSet"
    />
  </core-card>
</template>

<script>
import { defineComponent, computed, watch, ref } from "vue";
import { filter, sortBy } from "lodash/fp";

import {
  requestModel,
  createModel,
  modelToPosition
} from "@/app/store/requestModel.store";
import { createSocialNames } from "@/app/store/socialGroups.store";
import {
  isFsAllow,
  isLocked,
  isPositionCustom,
  getFsModel,
  setFsModel,
  getEmptyModel,
  setEmptyModel,
  addCustomSet
} from "@/app/store/requestModel.store";

import { formatCurrency } from "@/app/filters";

import CoreTable from "@/app/shared/core/Table/CoreTable";
import CoreTableBody from "@/app/shared/core/Table/CoreTableBody";
import CoreTableRow from "@/app/shared/core/Table/CoreTableRow";
import CoreTableCell from "@/app/shared/core/Table/CoreTableCell";
import CoreCard from "@/app/shared/core/CoreCard";
import CoreToggle from "@/app/shared/core/CoreToggle";
import CoreButton from "@/app/shared/core/CoreButton";
import Icon from "@/app/shared/core/Icon";
import { useRoute } from "vue-router";
import { find, map, sum } from "lodash";
import { learners } from "@/app/store/learners.store";
import Menu from "./Menu.vue";

export default defineComponent({
  components: {
    Icon,
    CoreButton,
    CoreToggle,
    CoreCard,
    CoreTableCell,
    CoreTableRow,
    CoreTableBody,
    CoreTable,
    Menu
  },

  props: {
    learners: { type: Array, default: () => [] },
    request: { type: Object, required: true },
    foodSets: { type: Array, default: () => [] },
    socialGroups: { type: Array, default: () => [] },
    date: { type: String, default: null }
  },

  setup(props, { emit }) {
    const route = useRoute();
    const showModal = ref(false);
    const modalOptions = ref({
      maxAmount: 0,
      originalSet: null,
      learnerId: null
    });

    const notArchivalFs = computed(() => {
      const filtered = filter(fs => !fs.archival)(props.foodSets);
      return sortBy("name")(filtered);
    });

    watch(
      () => props.request,
      request => createModel(request)
    );

    const closeRequest = () => {
      emit("close-request", {
        classId: route.params.classId,
        positions: modelToPosition(),
        date: props.date
      });
    };

    const isPositionInvalid = learner => {
      const { id, available } = learner;
      const m = requestModel.value[id];

      if (!m || m.locked) return false;

      const position = {
        operation: m.type === "custom" ? { items: m.value } : [],
        foodSetId: m.type === "set" ? m.value : []
      };

      let opPrice = null;
      if (position.operation && position.operation.items) {
        opPrice = map(position.operation.items, i => i.price * i.count);
      }

      let fsPrice = null;
      if (position.foodSetId) {
        fsPrice = map(
          position.foodSetId,
          fs => (props.foodSets.find(d => d.id === fs) || { price: 0 }).price
        );
      }

      const total = sum(opPrice) + sum(fsPrice);
      return total > available;
    };

    const openCustomSet = lId => {
      const position = requestModel.value[lId];
      const isCustom = position && position.type === "custom";
      const originalSet = isCustom ? position.value : null;

      const learner = find(learners.value, { id: lId });
      const maxAmount = learner ? learner.available : 0;

      modalOptions.value = { originalSet, maxAmount, learnerId: lId };
      showModal.value = true;
    };

    const closeOpenCustom = () => {
      showModal.value = false;
    };

    return {
      props,
      requestModel,
      modalOptions,
      showModal,
      openCustomSet,
      closeOpenCustom,
      isPositionCustom,
      addCustomSet,
      notArchivalFs,
      createSocialNames,
      isFsAllow,
      isLocked,
      getFsModel,
      setFsModel,
      getEmptyModel,
      setEmptyModel,
      formatCurrency,
      closeRequest,
      isPositionInvalid
    };
  }
});
</script>
