import { computed, ref } from "vue";
import {
  forEach,
  compact,
  isUndefined,
  isEmpty,
  includes,
  intersection,
  indexOf,
  isArray
} from "lodash/fp";

import { map, countBy, sum } from "lodash";

const requestModel = ref({});

// Computed
const positionsCount = computed(() => {
  const positions = modelToPosition();

  const foodSets = [];
  const operations = [];

  let count = {};

  forEach(pos => {
    if (isArray(pos.foodSetId)) foodSets.push(...pos.foodSetId);
    if (pos.operation) operations.push(1);
  })(positions);

  count = countBy(foodSets);
  if (!isEmpty(operations)) {
    count[-1] = sum(operations);
  }

  return count;
});

const createModel = (request = { positions: [] }) => {
  requestModel.value = {};
  forEach(position => {
    requestModel.value[position.userId] = {
      type: "empty",
      value: [],
      lockedFoodSets: position.foodSetId || []
    };
  })(request.positions);
};

const isLocked = (lId, fsId) => {
  const position = requestModel.value[lId];
  return !isUndefined(position) && includes(fsId)(position.lockedFoodSets);
};

const isFsAllow = (learner, fs) => {
  if (!(learner && fs)) return false;

  const asg = fs.allowedSocialGroup;
  if (!(asg && asg.length)) return false;

  if (includes("-1")(asg) && isEmpty(learner.socialGroups)) return true;

  for (let i = 0; i < asg.length; i++) {
    const rule = asg[i].split(",").map(Number);
    const inter = intersection(rule)(learner.socialGroups);

    if (!isEmpty(rule) && !isEmpty(inter)) return true;
  }

  return false;
};

const isPositionCustom = lId => {
  const position = requestModel.value[lId];
  return !isUndefined(position) && position.type === "custom";
};

const getFsModel = (lId, fsId) => {
  const position = requestModel.value[lId];
  return (
    !isUndefined(position) &&
    !isUndefined(position.value) &&
    includes(fsId)(position.value)
  );
};

const setFsModel = (lId, fsId) => {
  const locked = isLocked(lId, fsId);
  const position = requestModel.value[lId];

  if (!isUndefined(position) && locked) return true;

  if (position.type !== "set") {
    position.type = "set";
    position.value = [];
  }

  if (includes(fsId)(position.value)) {
    const index = indexOf(fsId)(position.value);
    console.log(index);
    position.value.splice(index, 1);

    if (isEmpty(position.value)) {
      position.type = "empty";
    }
  } else {
    position.value.push(fsId);
  }
};

const getEmptyModel = lId => {
  const position = requestModel.value[lId];
  return (
    isUndefined(position) ||
    (isEmpty(position.value) && isEmpty(position.lockedFoodSets))
  );
};

const setEmptyModel = lId => {
  const position = requestModel.value[lId];
  position.type = "empty";
  position.value = [];
};

const modelToPosition = () => {
  const mapped = map(requestModel.value, (position, lId) => {
    if (
      isUndefined(position) ||
      !isUndefined(position.locked) ||
      isEmpty(position.value)
    ) {
      return null;
    }

    const pos = { userId: parseInt(lId, 10) };

    if (position.type === "set") {
      pos.foodSetId = Array.from(position.value);
    } else {
      pos.operation = { info: position.value };
    }

    return pos;
  });

  return compact(mapped);
};

const addCustomSet = ({ learnerId, customSet }) => {
  requestModel.value[learnerId].type = "custom";
  requestModel.value[learnerId].value = customSet;
};

export {
  requestModel,
  positionsCount,
  createModel,
  isLocked,
  isFsAllow,
  isPositionCustom,
  getFsModel,
  setFsModel,
  getEmptyModel,
  setEmptyModel,
  modelToPosition,
  addCustomSet
};
