<template>
  <div class="action-sheet-container">
    <transition name="fade" appear @after-leave="emitClose">
      <div @click.self="hide" v-if="show" class="action-sheet-splash"></div>
    </transition>

    <transition name="slide" appear>
      <div class="action-sheet" v-if="show">
        <div class="action-sheet-header" v-if="title">
          <h5 class="action-sheet-title">{{ title }}</h5>
        </div>

        <div class="action-sheet-body">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String }
  },

  emits: ["close"],

  data: () => ({
    show: false
  }),

  mounted() {
    this.show = true;
    document.body.style.overflow = "hidden";
  },

  unmounted() {
    document.body.style.overflow = "auto";
  },

  methods: {
    emitClose() {
      this.$emit("close");
    },

    hide() {
      this.show = false;
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.32s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.32s ease;
  transform: translateY(0%);
}

.slide-leave-to,
.slide-enter-from {
  transform: translateY(100%);
}

.action-sheet-container {
  z-index: 500;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}
.action-sheet-splash {
  background: rgba(0, 0, 0, 0.64);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.action-sheet {
  background: #ffffff;
  /*border-radius: 24px 24px 0 0;*/
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.action-sheet-header {
  display: block;
  text-align: center;
  padding: 2px 20px;
  border-bottom: 1px solid #e1e1e1;
}

.action-sheet-title {
  margin: 0;
  text-align: center;
  display: block;
  font-size: 13px;
  padding: 6px 0;
  color: #141515;
  font-weight: 500;
}

.action-sheet-body {
  padding: 16px;
}
</style>
