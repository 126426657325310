<template lang="pug">
.relative.flex.flex-col(class="mt-6 md:mt-10")
  .flex.overflow-x-auto(v-if="ok")
    router-link.font-medium.h-10.mr-8.text-base.text-gray-400(
      v-for="cl in categoriesStore.categories.value",
      :key="cl.id",
      :to="{name: 'timesheet', params: {classId: cl.id}}",
      class="hover:text-gray-700 whitespace-nowrap"
     active-class="border-b-2 border-gray-900 text-gray-700"
    ) {{ +cl.name }} класс

  CoreInfo(v-if="!ok", type="danger", icon="mdi-alert-circle-outline")
    p Ошибка загрузки данных. Попробуйте позднее или перезагрузить страницу.

  CoreLoading(v-if="loading")
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

import { useCategoriesStore } from "@/app/modules/healths/store/categories.store";

export default defineComponent({
  setup() {
    const categoriesStore = useCategoriesStore();

    const ok = ref(true);

    onMounted(() => categoriesStore.loadCategories());

    return {
      categoriesStore,
      ok
    };
  }
});
</script>
