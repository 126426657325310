import { ref } from "vue";
import Api from "@/app/services/Api";
import { isUndefined } from "lodash";

const classes = ref([]);

const loadClasses = async () => {
  const { data } = await Api.classes.getClasses();
  classes.value = data;
};

const getClassName = cId => {
  const cl = classes.value.find(cl => cl.id === Number(cId));
  return isUndefined(cl) ? "Без названия" : `${cl.number}${cl.liter} класс`;
};

export { classes, loadClasses, getClassName };
