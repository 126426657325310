import { createApp } from "vue";
import VCalendar from "v-calendar";

import App from "./App.vue";

import router from "./app/router";
// import store from "./app/store";

import "./app/shared/assets/styles/index.css";
import "@mdi/font/css/materialdesignicons.css";

import "./registerServiceWorker";
import { registerComponents } from "./registerComponents";
// import "./registerFilters";

const app = createApp(App)
  .use(router)
  .use(VCalendar, {});
// .use(store);

registerComponents(app);

app.mount("#app");
