<template>
  <div class="bottom-menu" :class="[{ rounded }, variant]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    rounded: { type: Boolean },
    variant: { type: String }
  }
};
</script>

<style scoped>
.bottom-menu {
  min-height: 56px;
  position: fixed;
  z-index: 50;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 0;
}

.bottom-menu.rounded {
  border-radius: 24px 24px 0 0;
}

.bottom-menu.no-border {
  border: none;
}

.bottom-menu.light {
  background: rgba(255, 255, 255, 0.48);
  backdrop-filter: blur(8px);
  border: none;
}

.bottom-menu.transparent {
  background: transparent;
  border: none;
}
</style>
