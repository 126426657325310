<template lang="pug">
.flex.p-4.rounded.bg-gray-100.text-xs.font-medium(
  class="md:text-sm",
  :class="[type === 'danger' && 'bg-red-50 text-red-700', type === 'info' && 'bg-blue-50 text-blue-500']"
)
  Icon.text-xl.flex.mr-4(
    v-if="icon",
    :icon="icon",
    :class="[type === 'danger' && 'text-red-400 border-red-300', type === 'info' && 'text-blue-500 border-blue-300']"
  )
  .flex.flex-col.mt-1: slot
</template>

<script>
export default {
  props: {
    type: String,
    icon: String
  }
};
</script>
