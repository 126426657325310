import { createRouter, createWebHashHistory } from "vue-router";

import healths from "@/app/modules/healths/routes";
// import responsiveRoute from "@/app/router/responsiveRoute";

const routes = [
  ...healths,

  {
    path: "/mobile-demo",
    component: () => import("../Mobile.vue")
  },

  {
    path: "/login",
    name: "login",
    // component: responsiveRoute({
    //   xs: () =>
    //     import(/* webpackChunkName: "login" */ "../views/MobileLogin.vue"),
    //   md: () => import(/* webpackChunkName: "login" */ "../views/Login.vue")
    // })
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  }
];

const router = new createRouter({
  routes,
  history: createWebHashHistory()
});

export default router;
