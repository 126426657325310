<template>
  <div id="app">
    <router-view />
    <install-app />
  </div>
</template>

<script>
import AuthService from "@/app/modules/healths/services/auth.service";
import UnitService from "@/app/modules/healths/services/unit.service";

import { useAuthStore } from "@/app/modules/healths/store/auth.store";

import InstallApp from "@/app/shared/components/InstallApp";

export default {
  components: { InstallApp },
  async mounted() {
    try {
      const authStore = useAuthStore();

      const session = await AuthService.currentUser();
      const unit = await UnitService.getUnit();

      authStore.setSession(session);
      authStore.setUnit(unit[0].identifier);

      console.log(authStore.unit.value);

      sessionStorage.setItem("profile", JSON.stringify(session));
      sessionStorage.setItem("unit", unit[0].identifier);

      await this.$router.push({
        name: "unit",
        params: { unit_id: unit[0].identifier }
      });
    } catch {
      await this.$router.push({ name: "login" });
    }
  }
};
</script>
