<template lang="pug">
button.inline-flex.items-center.text-sm.rounded.text-white.shadow-sm(
  :class="classes",
  class="pl-4 pr-4 focus:outline-none text-center h-8 md:h-10"
): slot
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "default",
      validator(value) {
        return [
          "default",
          "primary",
          "success",
          "danger",
          "warning",
          "info"
        ].includes(value);
      }
    }
  },

  computed: {
    classes() {
      if (this.type === "primary") return "bg-gray-700";
      if (this.type === "success") return "bg-green-400";
      if (this.type === "danger") return "bg-red-400";
      if (this.type === "warning") return "bg-orange-400";
      if (this.type === "info") return "bg-blue-400";

      return "bg-white text-gray-700 border";
    }
  }
};
</script>
