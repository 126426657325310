<template lang="pug">
.field.relative.text-sm
  label.text-gray-500.font-medium(v-if="label", :for="id") {{ label }}
  .flex.items-center.mt-2.relative.bg-white.border-b-2.text-gray-700(class="focus-within:border-gray-700")
    input.w-full.h-12.outline-none.px-4(
      :id="id",
      :type="type",
      :placeholder="placeholder",
      :disabled="disabled",
      :value="modelValue",
      :required="required",
      :class="icon && 'pl-10'",
      @input="$emit('update:modelValue', $event.target.value)"
    )

    .absolute.text-gray-500.ml-1.bottom-0.mb-2.h-8.items-center.justify-center.inline-flex(
      v-if="icon"
    ): Icon(
      :icon="icon"
    )

//.field.relative.text-sm
//  label.text-gray-500.font-medium(v-if="label", :for="id") {{ label }}
//  .flex.items-center.mt-2.relative.shadow-md.bg-white.rounded-md.text-gray-700(
//    class="focus-within:border-pink-500"
//  )
//    input.w-full.h-12.rounded-md.outline-none.px-4(
//      :id="id",
//      :type="type",
//      :placeholder="placeholder",
//      :disabled="disabled",
//      :value="modelValue",
//      :required="required",
//      :class="icon && 'pl-10'",
//      class="focus:ring-2 ring-gray-700",
//      @input="$emit('update:modelValue', $event.target.value)"
//    )
//
//    .absolute.text-gray-500.ml-1.bottom-0.mb-2.h-8.w-8.items-center.justify-center.inline-flex(
//      v-if="icon"
//    ): Icon(
//      :icon="icon"
//    )
</template>

<script>
export default {
  props: {
    modelValue: [String, Number],
    id: { type: String, required: true },
    disabled: { type: [String, Boolean] },
    required: { type: [String, Boolean] },
    label: { type: String },
    placeholder: String,
    icon: String,
    type: { type: String, default: "text" }
  },

  emits: ["update:modelValue"]
};
</script>
