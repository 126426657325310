<template lang="pug">
.title: core-container
  .flex.flex-col
    h1: slot
    select-categories
</template>

<script>
import SelectCategories from "@/app/modules/healths/components/SelectCategories";
export default {
  components: { SelectCategories }
};
</script>

<style scoped>
.title {
  @apply text-3xl text-gray-700 font-bold border-b;
  @apply md:text-4xl;
}
</style>
