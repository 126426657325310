<template lang="pug">
div
  .flex.items-center.justify-between.p-4.border-b
    h3.text-xl.text-gray-900 Укажите {{ type === 'double' ? 'диапазон дат' : 'дату' }}
    .flex: slot

  .p-4.border-b.mb-4.grid.grid-cols-1.gap-2(
    v-if="type === 'double'",
    class="md:grid-cols-2"
  )
    .flex.items-center.justify-between(class="md:justify-start")
      p.mr-4.text-sm.font-medium Дата начала:
      //datepicker#begin(
      //  template="yyyy-MM-dd",
      //  :value="value.begin",
      //  min="",
      //  max="",
      //  @input="$emit('input', { ...value, begin: $event })"
      //)
      input.h-10.border.rounded.px-4.text-gray-700(
        type="date",
        :value="modelValue.begin",
        @input="$emit('update:modelValue', { ...modelValue, begin: $event.target.value })"
      )

    .flex.items-center.justify-between(class="md:justify-start")
      p.mr-4.text-sm.font-medium Дата окончания:
      input.h-10.border.rounded.px-4.text-gray-700(
        type="date",
        :value="modelValue.end",
        @input="$emit('update:modelValue', { ...modelValue, end: $event.target.value })"
      )
      //datepicker#end(
      //  template="yyyy-MM-dd",
      //  :value="value.end",
      //  min="",
      //  max="",
      //  @input="$emit('input', { ...value, end: $event })"
      //)

  .p-4.border-b.mb-4(v-else)
    .flex.items-center.justify-between(class="md:justify-start")
      p.mr-4.text-sm.font-medium Дата:
      //- datepicker#recovery(
      //-   template="yyyy-MM-dd",
      //-   :value="value",
      //-   :min="minDate",
      //-   :max="maxDate",
      //-   @input="$emit('input', $event)"
      //- )
      input.h-10.border.rounded.px-4.text-gray-700(
        type="date",
        :value="modelValue",
        :min="minDate",
        :max="maxDate",
        @input="$emit('update:modelValue', $event.target.value)"
      )
</template>

<script>
import datepicker from "pioneer-datepicker";
import "pioneer-datepicker/src/styles/default.css";

export default {
  components: { datepicker },

  props: {
    modelValue: { type: [Object, String], required: true },
    type: { type: String, default: "double" },
    minDate: { type: String, default: "" },
    maxDate: { type: String, default: "" }
  }
};
</script>
