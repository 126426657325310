import Axios from "axios";
import router from "@/app/router";

export const instance = Axios.create({
  baseURL: `${location.origin}/api/v1/teacher`,
  timeout: 12000
});

const auth = Axios.create({
  baseURL: `${location.origin}/api/v1`,
  timeout: 12000
});

instance.interceptors.response.use(null, err => {
  const { status, config } = (err && err.response) || {};
  if (status === 401 && !config.url.endsWith("/login")) {
    router.push({ name: "login" });
  }

  throw err;
});

const authorization = {
  login: (login, password) =>
    auth.post(
      `/auth?login=${login}&pwd=${password}`,
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }
    ),
  logout: () => auth.post(`/logout`),
  currentUser: () => auth.get(`/current-user`)
};

const classes = {
  getClasses: () => instance.get("/class"),
  getClass: classId => instance.get(`/class/${classId}`),
  getClassCurrentRequest: classId =>
    instance.get(`/class/${classId}/request/last`),
  getClassRequestList: (classId, limit = 10, offset = 0) =>
    instance.get(`/class/${classId}/request?limit=${limit}&offset=${offset}`),
  getClassRequestToday: (classId, date) =>
    instance.get(`/class/${classId}/request${date ? `?date=${date}` : ""}`),
  issueRequest: (classId, positions, date) =>
    instance.post(`/class/${classId}/issue`, { positions, date: date || null }),
  getClassLearners: (classId, date) =>
    instance.get(`/class/${classId}/learners${date ? `?date=${date}` : ""}`),
  getRequestsArchive: (classId, begin, end) =>
    instance.get(
      `/class/${classId}/request/archive?begin=${begin}&end=${end || begin}`
    )
};

const menu = {
  getFoodsets: () => instance.get("/foodSet"),
  getMenu: () => instance.get("/menu")
};

const requests = {
  getRequest: id => instance.get(`/request/${id}`),
  getPositions: id => instance.get(`/requests/${id}/positions`),
  closeRequest: (id, positions, date) =>
    instance.post(
      `/class/${id}/request/issue${date ? `?date=${date}` : ""}`,
      positions
    )
};

const socialGroups = {
  get: () => instance.get("/social_group")
};

const other = {
  time: () => instance.get("/time")
};

export default {
  ...other,
  authorization,
  classes,
  menu,
  requests,
  socialGroups
};
