<template>
  <div class="form-group basic">
    <div class="input-wrapper">
      <label class="label" :for="id">{{ label }}</label>
      <input
        :id="id"
        :type="type"
        :placeholder="placeholder"
        class="form-control"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />

      <icon
        v-if="modelValue.length"
        @click="clear"
        class="clear-input"
        icon="mdi-close-circle"
      ></icon>
    </div>
    <div class="input-info" v-if="desc">{{ desc }}</div>
  </div>
</template>

<script>
import Icon from "@/app/shared/core/Icon";
export default {
  components: { Icon },

  emits: ["update:modelValue"],

  props: {
    modelValue: { type: String },
    id: { type: String, required: true },
    type: { type: String, default: "text" },
    placeholder: { type: String },
    label: { type: String },
    desc: String
  },

  methods: {
    clear() {
      this.$emit("update:modelValue", "");
    }
  }
};
</script>

<style scoped>
.form-group.basic {
  width: 100%;
  padding: 8px 0;
  margin: 0;
}

.input-wrapper {
  position: relative;
}

.form-group .label {
  font-size: 11px;
  margin: 0;
  font-weight: 500;
  color: #141515;
  display: block;
  line-height: 1.2em;
  text-align: left;
}

.form-control {
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
}

.form-group.basic .form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 30px 0 0;
  border-radius: 0;
  height: 40px;
  color: #141515;
  font-size: 15px;
}

.form-group.basic .form-control:focus {
  border-bottom-color: #1e74fd;
  box-shadow: inset 0 -1px 0 0 #1e74fd;
}

.form-group .input-info {
  font-size: 11px;
  color: #a1a1a2;
  display: flex;
  align-items: center;
  min-height: 24px;
}

.form-group .clear-input {
  display: flex;
  height: 38px;
  font-size: 22px;
  color: #4f5050;
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 32px;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
}
</style>
