<template>
  <div class="head-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MobileHeadContainer"
};
</script>

<style scoped>
.head-container {
  padding-bottom: 48px;
  /*margin-bottom: 16px;*/
  position: relative;
  color: #ffffff;
  background: #1e74fd;
  margin-bottom: -24px;
}
.head-container:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 24px;
  border-radius: 24px 24px 0 0;
  background: #fff;
}
</style>
