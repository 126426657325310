import { ref } from "vue";
import UnitService from "@/app/modules/healths/services/unit.service";
import { useAuthStore } from "@/app/modules/healths/store/auth.store";
// import { isUndefined } from "lodash";

const categories = ref([]);

export function useCategoriesStore() {
  const loadCategories = async () => {
    const unit = useAuthStore().unit.value;
    console.log(unit);
    categories.value = await UnitService.getCategories(unit);
  };

  // const getClassName = cId => {
  //   const cl = classes.value.find(cl => cl.id === Number(cId));
  //   return isUndefined(cl) ? "Без названия" : `${cl.number}${cl.liter} класс`;
  // };

  return {
    categories,
    loadCategories
    // getClassName
  };
}
