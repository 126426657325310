import { ref } from "vue";
import { find, join, map } from "lodash/fp";

import Api from "@/app/services/Api";

const socialGroups = ref([]);

const loadSocialGroups = async () => {
  const { data } = await Api.socialGroups.get();
  socialGroups.value = data;
};

const createSocialNames = socials => {
  const mapped = map(
    id => (find(sg => sg.id === id)(socialGroups.value) || { name: "Н/Д" }).name
  )(socials);
  return join(",")(mapped);
};

export { socialGroups, loadSocialGroups, createSocialNames };
