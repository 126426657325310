<template>
  <button class="btn" :class="variant"><slot></slot></button>
</template>

<script>
export default {
  props: {
    variant: { type: String }
  }
};
</script>

<style scoped>
.btn {
  height: 40px;
  padding: 3px 18px;
  font-size: 13px;
  line-height: 1.2em;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  text-decoration: none;
  border-radius: 6px;
  border-width: 2px;
  outline: none;

  background: #1e74fd;
  border-color: #1e74fd;
  color: #ffffff;
}

.btn.full {
  width: 100%;
}
.btn.lg {
  height: 48px;
  padding: 3px 24px;
  font-size: 18px;
}
</style>
