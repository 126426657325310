import Axios from "axios";
import router from "@/app/router";
import { join } from "path";

export const instance = Axios.create({
  baseURL: location.origin + "/api/v2",
  timeout: 12000
});

instance.interceptors.response.use(
  ({ data }) => data,
  error => {
    const { status, config } = (error && error.response) || {};
    if (status === 401 && !config.url.endsWith("/login")) {
      router.push({ name: "login" });
    }

    throw error;
  }
);

const login = (login = "", password = "") => {
  const _login = login.trim();
  const _password = password.trim();

  if (!_login || !_password) throw Error("Username or password must be exist!");

  return instance.post(
    `/auth?login=${_login}&pwd=${_password}`,
    {},
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      }
    }
  );
};

const currentUser = () => instance.get(`/current-user`);

const logout = () => instance.post(`/logout`);

instance.unit = id => {
  const unitId =
    id !== undefined ? id : router.currentRoute.value.params.unit_id;

  const formatUrl = fn => (url, ...args) =>
    fn(join("unit", unitId.toString(), url), ...args);

  return {
    get: formatUrl(instance.get),
    post: formatUrl(instance.post),
    put: formatUrl(instance.put),
    delete: formatUrl(instance.delete),
    patch: formatUrl(instance.patch)
  };
};

export default { instance, login, logout, currentUser };
