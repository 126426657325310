<template lang="pug">
CoreButton(@click="logout")
  Icon(class="sm:mr-2", icon="mdi-logout") 
  span.hidden(class="sm:inline-block") Выйти
</template>

<script>
import Auth from "@/app/modules/healths/services/auth.service";

export default {
  methods: {
    async logout() {
      try {
        await Auth.logout();
        sessionStorage.removeItem("profile");
        sessionStorage.removeItem("unit");
      } catch {
        // PASS
      } finally {
        await this.$router.push({ name: "login" });
      }
    }
  }
};
</script>
