<template lang="pug">
.flex.flex-col.justify-center.mr-4.pr-4.pl-4.relative(class="md:pl-12")
  .inline-flex.w-8.h-8.ml-1.bg-gray-200.rounded-full.absolute.left-0.items-center.justify-center.text-gray-700.text-xs {{ profile.name[0] }}{{ profile.patronymic[0] }}
  p.hidden.text-xs.text-gray-500.font-medium(class="md:block") Здравствуйте!
  p.hidden.text-xs.text-gray-700.font-medium(class="md:block") {{ profile.name }} {{ profile.patronymic }}
</template>

<script>
export default {
  data() {
    return {
      profile: {
        name: "",
        patronymic: ""
      }
    };
  },

  mounted() {
    const profile = sessionStorage.getItem("profile");
    if (!profile) return;

    this.profile = JSON.parse(profile);
  }
};
</script>
