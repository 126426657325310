<template>
  <div class="app-capsule">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MobileView"
};
</script>

<style scoped>
.app-capsule {
  padding: 56px 0;
  min-height: 100vh;
}
</style>
