<template>
  <p>{{ props }}</p>
  <hr />
  <p>{{ requestModel }}</p>
  <hr />
  <p>Cfg: {{ learners }}</p>
</template>

<script>
import { defineComponent, watch } from "vue";
import { requestModel, createModel } from "@/app/store/requestModel.store";
import { learners, loadLearners } from "@/app/store/learners.store.js";

export default defineComponent({
  props: {
    request: { type: Object, required: true }
  },

  setup(props) {
    watch(
      () => props.request,
      request => createModel(request)
    );

    watch(
      () => props.request,
      request => loadLearners(request.classId)
    );

    return {
      props,
      requestModel,
      learners
    };
  }
});
</script>
