<template>
  <footer class="py-4 border-t">
    <core-container>
      <div class="flex items-center justify-between">
        <mip-logo></mip-logo>
        <div class="meta">
          <a href="mailto:hotline@1101001.ru">hotline@1101001.ru</a>
          <p class="text-xs font-medium text-gray-500">+7 4162 213 228</p>
        </div>
      </div>
    </core-container>
  </footer>
</template>

<script>
import CoreContainer from "@/app/shared/core/CoreContainer";
export default {
  name: "TheFooter",
  components: { CoreContainer }
};
</script>

<style scoped>
.meta {
  @apply text-right font-medium text-gray-700 text-sm leading-tight;
}
</style>
