<template lang="pug">
.px-4.mb-8.border-l-4(
  class="md:ml-4",
  :class="[request.status === 'success' && 'border-green-400', request.status === 'waiting' && 'border-blue-400', request.status === 'waiting_recovery' && 'border-red-400']"
)
  .flex.items-start.mb-4.border-b.pb-2.flex-col(
    class="md:justify-between md:items-center md:flex-row"
  )
    h3.text-xl.order-2(class="md:order-1") {{ formatRequestDate(request.date) }}
    span.px-2.py-1.rounded.text-xs.text-white.order-1.mb-2(
      class="md:mb-0 md:order-2",
      :class="[request.status === 'success' && 'bg-green-400', request.status === 'waiting' && 'bg-blue-400', request.status === 'waiting_recovery' && 'bg-red-400']"
    ) {{ shortStatuses[request.status] }}

  p.text-sm Дата и время заявки: {{ formatDate(request.date) }}
  p.text-xs.font-medium(
    :class="[request.status === 'success' && 'text-green-500', request.status === 'waiting' && 'text-blue-500', request.status === 'waiting_recovery' && 'text-red-500']"
  ) Статус: {{ statuses[request.status] }}

  .overflow-x-auto
    CoreTable.mt-4.w-full
      CoreTableHead
        CoreTableCell(isFirst) #
        CoreTableCell ФИО
        CoreTableCell Наименование
        CoreTableCell Итог
      CoreTableBody
        CoreTableRow(
          v-for="(learner, idx) in withPosition",
          :key="idx",
          v-show="true"
        )
          CoreTableCell(isFirst) {{ idx + 1 }}
          CoreTableCell {{ learner.surname }} {{ learner.name[0] }}.
          CoreTableCell
            p(v-for="foodSet in getFoodSets(learner.id)", :key="foodSet") {{ foodSet }}
            div(
              v-if="getOperation(learner.id).length",
              :class="getFoodSets(learner.id).length && 'mt-4'"
            )
              p.text-xs.text-gray-500 Свой заказ
              p.text-xs(
                v-for="(op, idx) in getOperation(learner.id)",
                :key="idx"
              ) {{ op }}
          CoreTableCell {{ getTotalSum(learner.id) }}
        CoreTableRow(isLast)
          CoreTableCell(:colspan="4")
            p.text-gray-500(v-if="withoutPosition.length")
              | Не имеют позиций:
              span.px-1.text-pink-600(
                v-for="learner in withoutPosition",
                :key="learner.id"
              ) {{ learner.surname }} {{ learner.name[0] }}.
            p.text-green-500(v-else) Все ученики имеют позиции
</template>

<script>
import { filter, find, findIndex, isEmpty, isObject, map, sum } from "lodash";
import { formatCurrency, formatDate, formatRequestDate } from "@/app/filters";
export default {
  props: {
    learners: { type: Array, default: () => [] },
    foodSets: { type: Array, default: () => [] },

    request: { type: Object, required: true }
  },

  data() {
    return {
      statuses: {
        success: "полностью обработана",
        waiting: "ожидает обработки в столовой",
        waiting_recovery: "ожидает обработки в столовой (восстановление)"
      },

      shortStatuses: {
        success: "Обработана",
        waiting: "Ожидает",
        waiting_recovery: "Ожидает (восстановление)"
      }
    };
  },

  computed: {
    withPosition() {
      return filter(this.learners, ({ id }) => !this.isEmpty(id));
    },

    withoutPosition() {
      return filter(this.learners, ({ id }) => this.isEmpty(id));
    }
  },

  methods: {
    formatDate,
    formatCurrency,
    formatRequestDate,
    isEmpty(userId) {
      const index = findIndex(this.request.positions, { userId });
      if (index < 0) return true;

      const op = this.request.positions[index];
      return isEmpty(op.foodSetId) && !isObject(op.operation);
    },

    getTotalSum(userId) {
      const positions = this.request.positions.find(
        pos => pos.userId === userId
      );

      let operationsPrice = null;
      if (positions.operation) {
        operationsPrice = positions.operation.info.map(
          item => item.price * item.count
        );
      }

      let foodSetsPrice = null;
      if (positions.foodSetId) {
        foodSetsPrice = map(positions.foodSetId, fs => {
          return (find(this.foodSets, { id: fs }) || { price: 0 }).price;
        });
      }

      return formatCurrency(sum(operationsPrice) + sum(foodSetsPrice));
    },

    getOperation(userId) {
      const positions = this.request.positions.find(
        pos => pos.userId === userId
      );

      let operationsName = [];
      if (positions.operation) {
        operationsName = map(
          positions.operation.info,
          item =>
            `${item.name} ${formatCurrency(item.price)}, x${
              item.count
            } = ${formatCurrency(item.price * item.count)}`
        );
      }

      return operationsName;
    },

    getFoodSets(userId) {
      const positions = this.request.positions.find(
        pos => pos.userId === userId
      );

      let foodSetsName = [];
      if (positions.foodSetId.length) {
        foodSetsName = map(
          positions.foodSetId,
          fs => find(this.foodSets, { id: fs }).name
        );
      }

      return foodSetsName;
    }
  }
};
</script>
