import { instance } from "@/app/modules/healths/services/auth.service";

const getUnit = () => {
  return instance.get("/unit");
};

const getItemTypes = () => {
  return instance.unit().get(`/teacher/time-sheet/item-type`);
};

const getCategories = () => {
  return instance.unit().get(`/teacher/categories`);
  // return instance.get(`/unit/${unitId}/teacher/categories`);
};

const getTimeSheet = (categoryId, date) => {
  return instance
    .unit()
    .get(`/teacher/time-sheet/category/${categoryId}/month/${date}`);
};

const updateTimeSheet = (date, params) => {
  return instance.unit().put(`/teacher/time-sheet/${date}`, params);
};

const getDate = () => {
  return instance.unit().get(`/teacher/current-date`);
};

export default {
  getUnit,
  getItemTypes,
  getCategories,
  getTimeSheet,
  updateTimeSheet,
  getDate
};
