import { ref, toRefs } from "vue";

const state = ref({
  session: null,
  unit: null
});

export function useAuthStore() {
  const setSession = session => {
    state.value.session = session;
  };

  const setUnit = unit => {
    console.log("SET_UNIT", unit);
    state.value.unit = unit;
  };

  return {
    ...toRefs(state.value),
    setSession,
    setUnit
  };
}
