<template>
  <div class="tabs" :class="{ sticky }" :style="{ top: top + 'px' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    sticky: { type: Boolean, default: false },
    top: { type: Number }
  }
};
</script>

<style scoped>
.tabs {
  position: relative;
  display: flex;
  background: #ffffff;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e1e1e1;
  overflow-x: auto;
}

.tabs.sticky {
  position: sticky;
  z-index: 30;
}
</style>
