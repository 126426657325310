<template lang="pug">
.mb-4
  CoreCard(title="Статус заявки"): RequestStats(
    @send="closeRequest",
    :status="status",
    :food-sets="foodSets"
  )

  Test(:request="request")

  CoreCard(title="Заявка"): .px-4.overflow-x-auto
    CoreTable.w-full
      //- CoreTableHead
      //-   CoreTableCell(isFirst) #
      //-   CoreTableCell ФИО, баланс
      //-   CoreTableCell Отсутствует
      //-   CoreTableCell Свой заказ
      //-   CoreTableCell(v-for="fs in fsTypesList", :key="fs.id") {{ fs.name }}

      CoreTableBody
        template(v-for="(learner, index) in learners")
          CoreTableRow(v-if="index % 10 === 0")
            CoreTableCell.text-xs.text-gray-500(isFirst) #
            CoreTableCell.text-xs.text-gray-500 ФИО, баланс
            CoreTableCell.text-xs.text-gray-500 Отсутствует
            CoreTableCell.text-xs.text-gray-500 Свой заказ
            CoreTableCell.text-xs.text-gray-500(v-for="fs in fsTypesList", :key="fs.id") {{ fs.name }}

          CoreTableRow(:class="isPositionInvalid(learner) && 'bg-red-200'")
            CoreTableCell(isFirst) {{ index + 1 }}
            CoreTableCell
              p {{ learner.surname }} {{ learner.name }}
              p.text-xs.text-pink-500(v-if="learner.socialGroups.length") 
                | ✓ Соц.: {{ createSocialGroupsNames(learner.socialGroups) }}
              p.text-xs.text-gray-500 
                | Доступно:
                span.ml-1.text-pink-700 {{ learner.available | currency }}
            CoreTableCell: CoreToggle(
              :value="getDisableModelValue(learner.id)",
              @change="disableModel(learner.id)"
            )
            CoreTableCell
              CoreButton(
                v-if="!learner.socialGroups.length",
                @click="openCustomSet(learner.id)",
                :type="isPositionCustom(learner.id) ? 'info' : 'default'"
              ) {{ isPositionCustom(learner.id) ? 'Изменить' : 'Создать' }}
            CoreTableCell(v-for="fs in fsTypesList", :key="fs.id")
              CoreToggle(
                v-if="isFsAllow(learner, fs)",
                :disabled="isLocked(learner.id, fs.id)",
                :value="getFoodSetModelValue(learner.id, fs.id)",
                @change="foodSetModel(learner.id, fs.id)"
              )

        CoreTableRow(isLast)
          CoreTableCell(isFirst, :colspan="4") Количество позиций
          CoreTableCell.text-pink-700(v-for="fs in fsTypesList", :key="fs.id") {{ countFoodSets(fs.id) }}
  Menu(
    v-if="showModal",
    :options="modalOptions",
    @close="closeOpenCustom",
    @result="addCustomSet"
  )
</template>

<script>
import Vue from "vue";
import {
  compact,
  filter,
  find,
  forEach,
  intersection,
  isEmpty,
  isUndefined,
  join,
  map,
  sortBy,
  sum
} from "lodash";
import Test from "@/app/shared/components/Test";

export default {
  components: { Test },
  props: {
    learners: { type: Array, default: () => [] },
    request: { type: Object, required: true },
    foodSets: { type: Array, default: () => [] },
    socialGroups: { type: Array, default: () => [] },
    date: { type: String, default: null }
  },

  data() {
    return {
      model: {},
      showModal: false,

      positionsCount: {},

      modalOptions: {
        maxAmount: 0,
        originalSet: null,
        learnerId: null
      }
    };
  },

  watch: {
    request() {
      if (!this.request) return;
      console.log(this.request);

      this.model = {};
      console.log(this.model);

      forEach(this.request.positions, position => {
        Vue.set(this.model, position.userId, {
          type: "empty",
          value: [],
          lockedFoodSets: position.foodSetId || []
        });
      });
    }
  },

  computed: {
    fsTypesList() {
      return sortBy(
        filter(this.foodSets, fs => !fs.archival),
        "name"
      );
    },

    status() {
      const positionsMap = map(
        this.model,
        m => m.lockedFoodSets.length || m.value.length
      );
      return sum(positionsMap) === 0 ? "empty" : this.request.status;
    }
  },

  methods: {
    closeRequest() {
      const { classId } = this.$route.params;
      this.$emit("close-request", {
        classId,
        positions: this.modelToPosition(),
        date: this.date
      });
    },

    modelToPosition() {
      return compact(
        map(this.model, (position, lId) => {
          if (!position || position.locked || isEmpty(position.value))
            return null;

          const pos = {
            userId: parseInt(lId, 10)
          };

          if (position.type === "set") {
            pos.foodSetId = Array.from(position.value);
          } else {
            pos.operation = {
              info: position.value
            };
          }

          return pos;
        })
      );
    },

    addCustomSet({ learnerId, customSet }) {
      this.model[learnerId].type = "custom";
      this.model[learnerId].value = customSet;
    },

    closeOpenCustom() {
      this.showModal = false;
    },

    countFoodSets(fs) {
      return sum(
        map(this.model, pos => {
          if (pos && pos.type === "set" && pos.value.includes(fs)) {
            return 1;
          } else {
            return 0;
          }
        })
      );
    },

    countCustomSet() {
      return sum(
        map(this.model, pos => {
          if (pos && pos.type === "custom") {
            return 1;
          }
        })
      );
    },

    openCustomSet(learnerId) {
      const positions = this.model[learnerId];
      const isCustom = positions && positions.type === "custom";
      const originalSet = isCustom ? positions.value : null;

      const learner = find(this.learners, { id: learnerId });
      const maxAmount = learner ? learner.available : 0;

      this.modalOptions = { originalSet, maxAmount, learnerId };

      this.showModal = true;
    },

    isLocked(learnerId, foodSetId) {
      return (
        this.model[learnerId] &&
        this.model[learnerId].lockedFoodSets.includes(foodSetId)
      );
    },

    isFsAllow(learner, fs) {
      if (!(learner && fs)) return false;

      const asg = fs.allowedSocialGroup;
      if (!(asg && asg.length)) return false;

      if (asg.includes("-1") && !learner.socialGroups.length) return true;

      // for (const rule of asg) {
      //   if (includes(learner.socialGroups, +rule)) return true;
      // }

      for (var i = 0; i < asg.length; i++) {
        const rule = asg[i].split(",").map(Number);
        const inter = intersection(rule, learner.socialGroups);

        if (rule.length === inter.length) {
          return true;
        }
      }

      return false;
    },

    isPositionCustom(learnerId) {
      return this.model[learnerId] && this.model[learnerId].type === "custom";
    },

    isPositionInvalid(learner) {
      const { id, available } = learner;
      const m = this.model[id];

      if (!m || m.locked) return false;

      const position = {
        operation: m.type === "custom" ? { items: m.value } : [],
        foodSetId: m.type === "set" ? m.value : []
      };

      let opPrice = null;
      if (position.operation && position.operation.items) {
        opPrice = map(position.operation.items, i => i.price * i.count);
      }

      let fsPrice = null;
      if (position.foodSetId) {
        fsPrice = map(
          position.foodSetId,
          fs => (this.foodSets.find(d => d.id === fs) || { price: 0 }).price
        );
      }

      const total = sum(opPrice) + sum(fsPrice);
      return total > available;
    },

    disableModel(learnerId) {
      this.model[learnerId].type = "empty";
      this.model[learnerId].value = null;
    },

    getDisableModelValue(learnerId) {
      return (
        isUndefined(this.model[learnerId]) ||
        (isEmpty(this.model[learnerId].value) &&
          isEmpty(this.model[learnerId].lockedFoodSets))
      );
    },

    foodSetModel(learnerId, foodSetId) {
      const isLocked = this.isLocked(learnerId, foodSetId);

      if (this.model[learnerId] && isLocked) return;

      if (this.model[learnerId].type !== "set") {
        this.model[learnerId].type = "set";
        this.model[learnerId].value = [];
      }

      if (this.model[learnerId].value.includes(foodSetId)) {
        this.model[learnerId].value.splice(
          this.model[learnerId].value.indexOf(foodSetId),
          1
        );

        if (!this.model[learnerId].value.length) {
          this.model[learnerId].type = "empty";
        }
      } else {
        this.model[learnerId].value.push(foodSetId);
      }
    },

    getFoodSetModelValue(learnerId, foodSetId) {
      const position = this.model[learnerId];
      return position && position.value && position.value.includes(foodSetId);
    },

    createSocialGroupsNames(socialGroups) {
      return join(
        map(
          socialGroups,
          id =>
            (this.socialGroups.find(sg => sg.id === id) || { name: "Н/Д" }).name
        ),
        ", "
      );
    }
  }
};
</script>
