<template>
  <div class="mip-logo">
    <img
      class="mip-logo__image"
      src="@/app/shared/assets/images/mip.svg"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "MipLogo"
};
</script>

<style scoped>
.mip-logo__image {
  @apply h-6;
}
</style>
