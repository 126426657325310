// import responsiveRoute from "@/app/router/responsiveRoute";

export default [
  {
    path: "/:unit_id",
    name: "unit",
    // component: responsiveRoute({
    //   xs: () =>
    //     import(
    //       /* webpackChunkName: "mobile.timesheet" */ "@/app/modules/healths/views/MobileTimesheet"
    //     ),
    //   md: () =>
    //     import(
    //       /* webpackChunkName: "timesheet" */ "@/app/modules/healths/views/Timesheet"
    //     )
    // }),
    component: () =>
      import(
        /* webpackChunkName: "timesheet" */ "@/app/modules/healths/views/Timesheet.vue"
      ),

    children: [
      {
        path: "timesheet/:classId",
        name: "timesheet",
        // component: responsiveRoute({
        //   xs: () =>
        //     import(
        //       /* webpackChunkName: "mobile.timesheet" */ "@/app/modules/healths/views/MobileTimesheet"
        //     ),
        //   md: () =>
        //     import(
        //       /* webpackChunkName: "timesheet" */ "@/app/modules/healths/views/Timesheet"
        //     )
        // })
        component: () =>
          import(
            /* webpackChunkName: "timesheet" */ "@/app/modules/healths/views/Timesheet.vue"
          )
      }
    ]
  }
  // {
  //   path: "/timesheet",
  //   name: "home.timesheet",
  //   component: () =>
  //     import(/* webpackChunkName: "timesheet" */ "@/app/modules/healths/views/Timesheet.vue")
  // },
  // {
  //   path: "/timesheet/:classId",
  //   name: "timesheet",
  //   component: () =>
  //     import(/* webpackChunkName: "timesheet" */ "@/app/modules/healths/views/Timesheet.vue")
  // }
];
