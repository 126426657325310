<template lang="pug">
core-card.p-4
  .grid.grid-cols-1.gap-2.mb-2(class="md:grid-cols-2 md:items-center")
    .flex.flex-col.mr-4.pr-4(class="md:border-r")
      p.text-base.font-medium.text-gray-700 Общая информация
      p.text-xs.font-medium.text-gray-500(
        :class="[status === 'empty' && 'text-gray-500' ,status === 'success' && 'text-green-500', status === 'waiting' && 'text-blue-500', status === 'waiting_recovery' && 'text-red-500']"
      ) Состояние заявки: {{ statuses[status] }}

    //- DEPRECATED
    .flex.flex-col.mr-4.pr-4(class="md:border-r")
      p.text-base.font-medium.text-gray-700 Количество позиций
      p.text-xs.font-medium.text-gray-500(v-if="Object.keys(positionsCount).length")
        span.mr-4(v-for="(value, key) in positionsCount", :key="key")
         | {{ getFsName(key) }}:
         span.ml-2.text-red-600 {{ value }}
      p.text-xs.font-medium.text-gray-500(v-else) Изменений в заявке нет

</template>

<script>
import { defineComponent } from "vue";

import { positionsCount } from "@/app/store/requestModel.store";
import { getFsName } from "@/app/store/foodSets.store";

export default defineComponent({
  props: {
    status: String
  },

  setup(props) {
    const statuses = {
      empty: "ожидает создания",
      success: "полностью обработана",
      waiting: "ожидает обработки в столовой",
      waiting_recovery: "ожидает обработки в столовой (восстановление)"
    };
    return {
      props,
      statuses,
      positionsCount,
      getFsName
    };
  }
});
// export default {
//   props: {
//     status: String,
//     foodSets: { type: Array, default: () => [] },
//     positionsCount: { type: Object, default: () => {} }
//   },
//
//   data() {
//     return {
//       statuses: {
//         empty: "ожидает создания",
//         success: "полностью обработана",
//         waiting: "ожидает обработки в столовой",
//         waiting_recovery: "ожидает обработки в столовой (восстановление)"
//       }
//     };
//   },
//
//   methods: {
//     // getFoodSetName(id) {
//     //   if (+id === -1) return "Свой заказ";
//     //   return (this.foodSets.find((fs) => fs.id === +id) || { name: "Н/Д" })
//     //     .name;
//     // },
//   }
// };
</script>
