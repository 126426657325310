<template lang="pug">
CoreButton(@click="goToArchive")
  Icon(class="sm:mr-2", icon="mdi-archive-outline") 
  span.hidden(class="sm:inline-block") Архив заявок
</template>

<script>
export default {
  methods: {
    goToArchive() {
      const { classId } = this.$route.params;
      this.$router.push({ name: "archive", params: { classId } });
    }
  }
};
</script>
