import { ref } from "vue";
import Api from "@/app/services/Api";

const foodSets = ref([]);

const loadFoodSets = async () => {
  const { data } = await Api.menu.getFoodsets();
  foodSets.value = data;
};

const getFsName = id => {
  if (Number(id) === -1) return "Свой заказ";
  return (foodSets.value.find(fs => fs.id === Number(id)) || { name: "Н/Д" })
    .name;
};

export { foodSets, loadFoodSets, getFsName };
