<template lang="pug">
CoreButton(type="danger", @click="goToRecovery")
  Icon(class="sm:mr-2", icon="mdi-cached") 
  span.hidden(class="sm:inline-block") Заявка восстановления
</template>

<script>
export default {
  methods: {
    goToRecovery() {
      const { classId } = this.$route.params;
      this.$router.push({ name: "recovery", params: { classId } });
    }
  }
};
</script>
