export default function formatRequestDate(date) {
  if (!date) return "Без даты";
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря"
  ];
  const _date = new Date(date);

  const year = _date.getFullYear();
  const month = _date.getMonth();
  const day = _date.getDate();

  return `Заявка на ${day} ${months[month]} ${year}г.`;
}
