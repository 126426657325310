import { ref } from "vue";
import Api from "@/app/services/Api";

import { sortBy } from "lodash/fp";

const learners = ref([]);

const loadLearners = async (classId, date) => {
  const { data } = await Api.classes.getClassLearners(classId, date);
  learners.value = sortBy(["surname", "name"])(data);
};

export { learners, loadLearners };
