<template lang="pug">
.rounded-md.shadow-xl.bg-white.pb-2.mb-4
  //- .flex.flex-col.p-4.border-b(v-if="title || description")
  .flex.justify-between.items-center
    .flex.flex-col.p-4(v-if="title || description")
      p.text-base.font-medium {{ title }}
      p.text-xs.text-gray-500(v-if="description") {{ description }}
    .flex.px-2: slot(name="actions")
  .block: slot
</template>

<script>
export default {
  props: {
    title: String,
    description: String
  }
};
</script>
