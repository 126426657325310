<template>
  <div class="list-item" :class="{ icon, active }">
    <div
      class="list-item-icon"
      v-if="icon"
      :style="{ background: iconBackground, color: iconColor }"
    >
      <icon :icon="icon"></icon>
    </div>

    <div class="list-item-body">
      <slot></slot>

      <icon
        class="list-item-check"
        v-if="active"
        icon="mdi-check-circle"
      ></icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    iconBackground: String,
    iconColor: String,
    active: Boolean
  }
};
</script>

<style scoped>
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 50px;
  padding: 8px 16px;
  width: 100%;
}
.list-item.active {
  @apply bg-blue-50;
}

.list-item-icon {
  min-width: 36px;
  max-width: 36px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99999px;
  margin-right: 16px;
  line-height: 1em;
  font-size: 20px;
}

.list-item-body {
  flex: 1;
  display: flex;
  position: relative;
}

.list-item-check {
  position: absolute;
  font-size: 22px;
  color: #1e74fd;
  right: 0;
}
</style>
