<template>
  <div class="item" :class="{ active }">
    <div class="flex flex-col">
      <icon class="icon" v-if="icon" :icon="icon"></icon>
      <strong v-if="title" class="font-medium mt-2">{{ title }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    active: { type: Boolean, default: false }
  }
};
</script>

<style scoped>
.item {
  font-size: 9px;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
  height: 56px;
  line-height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /*border-top: 2px solid transparent;*/
  color: #141515;
}

.item .icon {
  font-size: 24px;
}

.item.active {
  /*border-top-color: #1e74fd;*/
  color: #1e74fd;
}
</style>
