<template>
  <div
    v-if="deferredPrompt"
    class="fixed z-50 bottom-4 md:bottom-auto md:top-4 left-4 right-4 bg-gray-700 rounded-md shadow-md md:left-auto md:w-80"
  >
    <div class="p-4 flex items-center justify-between">
      <h3 class="text-base text-white font-medium">Установка приложения</h3>

      <button
        class="rounded bg-gray-600 inline-flex text-white items-center justify-center w-8 h-8"
        @click="dismiss"
      >
        <icon icon="mdi-close" />
      </button>
    </div>

    <div class="p-4">
      <p class="text-sm text-white">
        Для полноценной работы личного приложения необходимо его установить!
      </p>

      <div class="mt-4 flex flex-row-reverse">
        <button
          @click="install"
          class="outline-none px-4 h-8 rounded bg-gray-600 text-white text-sm font-medium"
        >
          Установить
        </button>
        <button
          @click="dismiss"
          class="rounded outline-none mr-4 px-4 text-sm font-medium text-gray-400"
        >
          Позже
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  setup() {
    const deferredPrompt = ref(false);

    const install = () => {
      deferredPrompt.value.prompt();
    };
    const dismiss = () => {
      deferredPrompt.value = null;
    };

    onMounted(() => {
      window.addEventListener("beforeinstallprompt", e => {
        if (window.innerWidth > 768) return;
        e.preventDefault();
        deferredPrompt.value = e;
      });

      window.addEventListener("appinstalled", () => {
        deferredPrompt.value = null;
      });
    });

    return {
      deferredPrompt,
      install,
      dismiss
    };
  }
});
</script>
