<template>
  <div class="headerButton">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.headerButton {
  min-height: 56px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: relative;
}
</style>
