<template>
  <div class="tab-item" :class="{ active }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    active: { type: Boolean }
  }
};
</script>

<style scoped>
.tab-item {
  flex: 1;
  width: 100%;
  max-width: 25%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  white-space: nowrap;
  color: #4f5050;
  font-weight: 500;
  font-size: 13px;
  border-bottom: 2px solid transparent;
  line-height: 1.2em;
  padding: 6px 10px;
  height: 56px;
}

.tab-item.active {
  color: #1e74fd;
  border-bottom-color: #1e74fd;
}
</style>
