<template>
  <div class="search-box">
    <form>
      <div class="search-group">
        <icon class="search-icon" icon="mdi-magnify"></icon>
        <input
          class="search-input"
          type="text"
          :value="modelValue"
          @input="$emit('update:modelValue')"
          :placeholder="placeholder"
        />
        <icon
          @click="$emit('close')"
          class="close-icon"
          icon="mdi-close-circle"
        ></icon>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: { type: String, default: "Поиск..." },
    modelValue: { type: String }
  }
};
</script>

<style scoped>
.search-box {
  position: fixed;
  top: 0;
  display: flex;
  @apply shadow-md;
  background: #ffffff;
  left: 0;
  right: 0;
  z-index: 100;
}

.search-box form {
  width: 100%;
}

.search-group {
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon {
  font-size: 24px;
  left: 8px;
  top: 0;
  color: #a1a1a2;
  position: absolute;
  width: 46px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  padding: 0 56px;
  width: 100%;
  height: 56px;
  background: transparent;
  font-size: 17px;
  color: #141515;
  outline: none;
}

.close-icon {
  font-size: 24px;
  right: 8px;
  top: 0;
  color: #a1a1a2;
  position: absolute;
  width: 46px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
